import type { NewsroomGallery } from '@prezly/sdk';
import { useCurrentLocale, useNewsroomContext } from '@prezly/theme-kit-nextjs';
import { useSyncedRef } from '@react-hookz/web';
import { Router, useRouter } from 'next/router';
import Script from 'next/script';
import type { ParsedUrlQuery } from 'querystring';
import type { Dispatch, SetStateAction } from 'react';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { SONY_TEALIUM_ASYNC_SCRIPT_ID, SonyEvent, TEALIUM_SCRIPT_SRC } from './constants';
import type { SearchStateParams } from './lib';
import { updateDataLayerScript } from './lib';

interface SonyAnalyticsContextType {
    isAsyncScriptLoaded: boolean;
    fireEvent: (name: SonyEvent, detail?: any) => void;
    fireComponentInteractionEvent: (detail: ComponentInteractionEventDetail) => void;
    onSearchStateUpdate: Dispatch<SetStateAction<SearchStateParams>>;
}

interface ComponentInteractionEventDetail {
    /**
     * `navigation-click` corresponds to links to other pages (e.g MySony link)
     * `in-page-clicks` to items that cause interactions (e.g burger icon and categories)
     * `menu-item-click` refers to submenu links (e.g "Store" link)
     */
    interactionType: 'navigation-click' | 'in-page-click' | 'menu-item-click';
    componentType: 'presscentre_header' | 'presscentre_footer';
    // eslint-disable-next-line @typescript-eslint/naming-convention
    componentID:
        | 'seu-press-centre-main-header'
        | 'seu-press-centre-secondary-header'
        | 'seu-press-centre-footer';
    actionableItemValue: string;
}

const SonyAnalyticsContext = createContext<SonyAnalyticsContextType | null>(null);

export function useSonyAnalytics() {
    const context = useContext(SonyAnalyticsContext);
    if (!context) {
        throw new Error('`useSonyAnalytics` must be used within a `SonyAnalyticsContextProvider`');
    }

    return context;
}

function getSearchStateFromQuery(
    query: ParsedUrlQuery,
    initialResultsCount?: number,
): SearchStateParams {
    if (!query.query || typeof query.query !== 'string') {
        return {
            query: '',
            resultsCount: 0,
        };
    }

    return {
        query: query.query,
        resultsCount: initialResultsCount ?? 0,
        category: Array.isArray(query.category) ? query.category.join(',') : query.category,
    };
}

export function SonyAnalyticsContextProvider({
    children,
    currentGallery,
    initialResultsCount,
}: {
    children: React.ReactNode;
    currentGallery?: NewsroomGallery;
    initialResultsCount?: number;
}) {
    const locale = useCurrentLocale();
    const { pathname, query } = useRouter();

    const [isAsyncScriptLoaded, setAsyncScriptLoaded] = useState(false);
    const [searchState, setSearchState] = useState<SearchStateParams>(
        getSearchStateFromQuery(query, initialResultsCount),
    );
    const { currentStory } = useNewsroomContext();
    const currentStoryRef = useSyncedRef(currentStory);
    const currentGalleryRef = useSyncedRef(currentGallery);

    // Since the async script tag is constructed on the fly, we can't properly attach `onLoad` event listener to it.
    // This is a workaround for this.
    useEffect(() => {
        function handleAsyncScriptLoaded() {
            setAsyncScriptLoaded(true);
        }

        document.addEventListener('sony-async-script-loaded', handleAsyncScriptLoaded);

        return () =>
            document.removeEventListener('sony-async-script-loaded', handleAsyncScriptLoaded);
    }, []);

    useEffect(() => {
        function handlePageStateUpdated(event: any) {
            if (process.env.NODE_ENV === 'development') {
                // eslint-disable-next-line no-console
                console.log('page_state_updated', event.detail);
                if (window.buildSonyDataLayer) {
                    // eslint-disable-next-line no-console, new-cap
                    console.log(window.buildSonyDataLayer().digitalData);
                }
            }
        }

        document.addEventListener('page_state_updated', handlePageStateUpdated);

        return () => document.removeEventListener('page_state_updated', handlePageStateUpdated);
    }, []);

    const fireEvent = useCallback((name: SonyEvent, detail?: any) => {
        const event = new CustomEvent(name, {
            detail,
        });
        if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.log(`Firing event: ${name}`, detail);
        }

        document.dispatchEvent(event);
    }, []);

    const fireComponentInteractionEvent = useCallback(
        (detail: ComponentInteractionEventDetail) => {
            fireEvent(SonyEvent.COMPONENT_INTERACTION, detail);
        },
        [fireEvent],
    );

    useEffect(() => {
        if (!isAsyncScriptLoaded) {
            return;
        }

        window.pressCenterDataLayerReady = true;
        fireEvent(SonyEvent.PAGE_STATE_UPDATED, {
            origin: 'server',
        });
    }, [isAsyncScriptLoaded, fireEvent]);

    // Data layer for Tealium script needs to be reset on every route change.
    useEffect(() => {
        function routeChangeComplete(_: string, options?: { shallow?: boolean }) {
            // Ignore shallow route changes.
            if (options?.shallow) {
                return;
            }

            updateDataLayerScript({
                locale,
                pathname,
                query,
                pageData: {
                    currentStory: currentStoryRef.current,
                    currentGallery: currentGalleryRef.current,
                },
                searchState,
            });

            fireEvent(SonyEvent.PAGE_STATE_UPDATED, {
                origin: 'client',
            });
        }

        Router.events.on('routeChangeComplete', routeChangeComplete);
        return () => {
            Router.events.off('routeChangeComplete', routeChangeComplete);
        };

        // `currentStoryRef` and `currentGalleryRef` are synced refs
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locale, pathname, JSON.stringify(query), fireEvent, searchState]);

    // Reset search state when leaving /search page
    useEffect(() => {
        if (pathname !== '/search') {
            setSearchState({
                query: '',
                resultsCount: 0,
            });
        }
    }, [pathname]);

    useEffect(() => {
        if (pathname === '/search' && isAsyncScriptLoaded) {
            updateDataLayerScript({
                locale,
                pathname,
                query,
                pageData: {
                    currentStory: currentStoryRef.current,
                    currentGallery: currentGalleryRef.current,
                },
                searchState,
            });

            if (process.env.NODE_ENV === 'development') {
                // eslint-disable-next-line no-console
                console.log('data layer updated');
                if (window.buildSonyDataLayer) {
                    // eslint-disable-next-line no-console, new-cap
                    console.log(window.buildSonyDataLayer().digitalData);
                }
            }
        }

        // `currentStoryRef` and `currentGalleryRef` are synced refs
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locale, pathname, JSON.stringify(query), searchState, isAsyncScriptLoaded]);

    const contextValue = useMemo<SonyAnalyticsContextType>(
        () => ({
            isAsyncScriptLoaded,
            fireEvent,
            fireComponentInteractionEvent,
            onSearchStateUpdate: setSearchState,
        }),
        [isAsyncScriptLoaded, fireEvent, fireComponentInteractionEvent],
    );

    return (
        <SonyAnalyticsContext.Provider value={contextValue}>
            {children}

            <Script
                id={SONY_TEALIUM_ASYNC_SCRIPT_ID}
                dangerouslySetInnerHTML={{
                    __html: `
                        (function(a,b,c,d){ a='${TEALIUM_SCRIPT_SRC}'; b=document;c='script';d=b.createElement(c);d.src=a;d.type='text/java'+c;d.async=true;
                        d.onload=function(){document.dispatchEvent(new Event('sony-async-script-loaded'))};
                        a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a); })();
                    `,
                }}
            />
        </SonyAnalyticsContext.Provider>
    );
}
