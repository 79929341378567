export const TEALIUM_SYNC_SCRIPT_SRC =
    process.env.NEXT_PUBLIC_USE_QA_SCRIPTS || process.env.NODE_ENV === 'development'
        ? 'https://tags.tiqcdn.com/utag/sony-marketing/seu/qa/utag.sync.js'
        : 'https://tags.tiqcdn.com/utag/sony-marketing/seu/prod/utag.sync.js';

export const TEALIUM_SCRIPT_SRC =
    process.env.NEXT_PUBLIC_USE_QA_SCRIPTS || process.env.NODE_ENV === 'development'
        ? 'https://tags.tiqcdn.com/utag/sony-marketing/seu/qa/utag.js'
        : 'https://tags.tiqcdn.com/utag/sony-marketing/seu/prod/utag.js';

export const SONY_DATA_LAYER_SCRIPT_ID = 'sony-data-layer';
export const SONY_TEALIUM_SYNC_SCRIPT_ID = 'sony-tealium-sync';
export const SONY_TEALIUM_ASYNC_SCRIPT_ID = 'sony-tealium-async';
export const SONY_TEALIUM_WEBSITE_PLATFORM_ID = 'sony-website-platform';

export enum SonyEvent {
    PAGE_STATE_UPDATED = 'page_state_updated',

    EMAIL_SUBSCRIBED = 'emailSubscribed',
    CLICKED_SEARCH_RESULT = 'clickedSearchResult',
    CLICKED_SOCIAL_MEDIA = 'clickedSocial',
    CLICKED_BANNER = 'clickedBanner',
    ASSET_DOWNLOADED = 'downloaded',
    FILTERS_UPDATED = 'filtersUpdated',
    SHARED_SOCIAL = 'sharedSocial',
    PAGINATION_CLICKED = 'paginationClicked',

    COMPONENT_INTERACTION = 'componentInteraction',
}
