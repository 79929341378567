import type { ReactNode } from 'react';
import { createContext, useContext } from 'react';

import type { FooterData } from '@/constants';

const FooterContext = createContext<FooterData>({});

interface Props {
    children: ReactNode;
    data: FooterData;
}

export function FooterContextProvider({ children, data }: Props) {
    return <FooterContext.Provider value={data}>{children}</FooterContext.Provider>;
}

export function useFooterContext() {
    return useContext(FooterContext);
}
