/* eslint-disable @typescript-eslint/no-use-before-define */
import type { NewsroomGallery, Story } from '@prezly/sdk';
import type { LocaleObject } from '@prezly/theme-kit-core';
import type { ParsedUrlQuery } from 'querystring';

import { SONY_DATA_LAYER_SCRIPT_ID, SONY_TEALIUM_SYNC_SCRIPT_ID } from './constants';

export interface GetPageDataParams {
    currentStory?: Story;
    currentGallery?: NewsroomGallery;
}

export interface SearchStateParams {
    query: string;
    resultsCount: number;
    category?: string;
}

function getPageTemplate(pathname: string, { currentGallery }: GetPageDataParams) {
    switch (pathname) {
        case '/category/[slug]':
        case '/news':
            return 'news-list';
        case '/contacts':
            return 'contacts-list';
        case '/search':
            return 'search-results';
        case '/[slug]':
        case '/s/[uuid]':
            return 'news-detail';
        case '/media':
            return 'images_videos';
        case '/media/album/[uuid]': {
            if (currentGallery?.type === 'video') {
                return 'video-detail';
            }

            return 'image-detail';
        }
        case '/':
            return 'home';
        case '/_error':
            return 'error';
        default:
            return '';
    }
}

function getPageName(
    pathname: string,
    query: ParsedUrlQuery,
    { currentGallery, currentStory }: GetPageDataParams,
) {
    switch (pathname) {
        case '/[slug]':
            return `news-detail:${currentStory?.title || query.slug}`;
        case '/s/[uuid]':
            return `news-detail:${currentStory?.title || query.uuid}`;
        case '/media/album/[uuid]':
            if (currentGallery?.type === 'video') {
                return `video-detail:${currentGallery?.name || query.uuid}`;
            }

            return `image-detail:${currentGallery?.name || query.uuid}`;
        default:
            return getPageTemplate(pathname, { currentGallery, currentStory });
    }
}

interface GetDataLayerParams {
    locale: LocaleObject;
    pathname: string;
    query: ParsedUrlQuery;
    pageData: GetPageDataParams;
    searchState?: SearchStateParams;
}

export function getDataLayerScriptHtml({
    locale,
    pathname,
    query,
    pageData,
    searchState,
}: GetDataLayerParams) {
    return `
        if (typeof window.buildSonyDataLayer === 'undefined') {
            window.buildSonyDataLayer = function() {
                    var sony = {};
                    sony.digitalData = {};
                    sony.digitalData.version = '1';

                    sony.digitalData.page = {};
                    sony.digitalData.page.country = '${locale.toRegionCode()}';
                    sony.digitalData.page.language = '${locale.toNeutralLanguageCode()}';
                    sony.digitalData.page.template = '${getPageTemplate(pathname, pageData)}';
                    sony.digitalData.page.name = '${escape(
                        getPageName(pathname, query, pageData),
                    )}';
                    sony.digitalData.page.section = 'prs';
                    sony.digitalData.page.responseCode = '200';

                    ${
                        pathname === '/search' && searchState
                            ? `
                                sony.digitalData.search = ${JSON.stringify({
                                    term: searchState.query,
                                    resultCount: searchState.resultsCount || 0,
                                    category: searchState.category || '',
                                })};
                            `
                            : ''
                    }

                    return sony;
            };
        }
    `;
}

// TODO: Figure out if we can simply re-assign the `window.buildSonyDataLayer` object.
export function updateDataLayerScript({
    locale,
    pathname,
    query,
    pageData,
    searchState,
}: {
    locale: LocaleObject;
    pathname: string;
    query: ParsedUrlQuery;
    pageData: GetPageDataParams;
    searchState?: SearchStateParams;
}) {
    const tealiumSyncScriptTag = document.getElementById(SONY_TEALIUM_SYNC_SCRIPT_ID);
    if (!tealiumSyncScriptTag || !tealiumSyncScriptTag.parentNode) {
        // eslint-disable-next-line no-console
        console.error('Failed to update data layer - missing Tealium sync script tag');
        return;
    }

    const oldScript = document.getElementById(SONY_DATA_LAYER_SCRIPT_ID);
    const updatedHtml = getDataLayerScriptHtml({
        locale,
        pathname,
        query,
        pageData,
        searchState,
    });
    // No need to re-insert the script if there is nothing to update
    if (oldScript?.innerHTML === updatedHtml) {
        return;
    }

    if (oldScript) {
        document.getElementById(SONY_DATA_LAYER_SCRIPT_ID)?.remove();
    }
    if (window.buildSonyDataLayer) {
        window.buildSonyDataLayer = undefined;
    }

    const updatedScript = document.createElement('script');
    updatedScript.id = SONY_DATA_LAYER_SCRIPT_ID;
    updatedScript.innerHTML = updatedHtml;

    tealiumSyncScriptTag.parentNode.insertBefore(updatedScript, tealiumSyncScriptTag);
}

function escape(string: string) {
    return string
        .replace(/\\/g, '\\\\')
        .replace(/'/g, "\\'")
        .replace(/"/g, '\\"')
        .replace(/\n/g, '\\n')
        .replace(/\r/g, '\\r')
        .replace(/\t/g, '\\t');
}
