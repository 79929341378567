import type { ReactNode } from 'react';
import { createContext, useContext } from 'react';

import type { HeaderData } from '@/constants';

const HeaderContext = createContext<HeaderData>({ productSections: [] });

interface Props {
    children: ReactNode;
    data: HeaderData;
}

export function HeaderContextProvider({ children, data }: Props) {
    return <HeaderContext.Provider value={data}>{children}</HeaderContext.Provider>;
}

export function useHeaderContext() {
    return useContext(HeaderContext);
}
