import { AnalyticsProvider } from '@prezly/analytics-nextjs';
import type { NewsroomGallery } from '@prezly/sdk';
import { DEFAULT_LOCALE, LocaleObject } from '@prezly/theme-kit-core';
import type { PageProps } from '@prezly/theme-kit-nextjs';
import { NewsroomContextProvider } from '@prezly/theme-kit-nextjs';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import type { AppProps } from 'next/app';
import { useMemo } from 'react';
import { IntlProvider } from 'react-intl';

import { FooterContextProvider } from '@/contexts/footer';
import { HeaderContextProvider } from '@/contexts/header';
import { SonyAnalyticsContextProvider } from '@/modules/SonyAnalytics';
import type { BasePageProps } from 'types';

import '@prezly/content-renderer-react-js/styles.css';
import '@prezly/uploadcare-image/build/styles.css';
import 'modern-normalize/modern-normalize.css';
import '../styles/styles.globals.scss';

const queryClient = new QueryClient();

function App({ Component, pageProps }: AppProps) {
    const { footerData, newsroomContextProps, translations, headerData, ...customPageProps } =
        pageProps as PageProps &
            BasePageProps & { gallery?: NewsroomGallery; resultsCount?: number };

    const { localeCode, newsroom, currentStory } = newsroomContextProps || {
        localeCode: DEFAULT_LOCALE,
    };
    const locale = useMemo(() => LocaleObject.fromAnyCode(localeCode), [localeCode]);
    const plausibleDomains = [newsroom.plausible_site_id, 'rollup.customers.prezly.com'].join(',');

    // `newsroomContextProps` can be undefined, if there was error when fetching the newsroom props.
    // This can happen due to connection issues, or incorrect credentials in your .env file.
    // In this case, a 500 error page would be rendered, which shouldn't rely on the Newsroom Context (especially when statically generated).
    if (!newsroomContextProps) {
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <Component {...customPageProps} />;
    }

    /* eslint-disable react/jsx-props-no-spreading */
    return (
        <NewsroomContextProvider {...newsroomContextProps}>
            <QueryClientProvider client={queryClient}>
                <IntlProvider
                    locale={locale.toHyphenCode()}
                    defaultLocale={DEFAULT_LOCALE}
                    messages={translations}
                >
                    <AnalyticsProvider
                        // We're only using `@prezly/analytics` for automatic asset clicks at this point.
                        isEnabled={false}
                        isPlausibleEnabled
                        plausibleDomain={plausibleDomains}
                        newsroom={newsroom}
                        story={currentStory}
                    >
                        <SonyAnalyticsContextProvider
                            currentGallery={customPageProps.gallery}
                            initialResultsCount={customPageProps.resultsCount}
                        >
                            <HeaderContextProvider data={headerData}>
                                <FooterContextProvider data={footerData}>
                                    <Component {...customPageProps} />
                                </FooterContextProvider>
                            </HeaderContextProvider>
                        </SonyAnalyticsContextProvider>
                    </AnalyticsProvider>
                </IntlProvider>
            </QueryClientProvider>
        </NewsroomContextProvider>
    );
    /* eslint-enable react/jsx-props-no-spreading */
}

export default App;
